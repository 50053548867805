module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Template","short_name":"Gatsby Template","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"fullscreen","icon":"static/assets/app-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5a5e577ac9acf6426ffe33d22661fd77"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"debug":true,"appendScript":"/Users/yvesgurcan/Projects/template-gatsby/src/serviceWorker.js","clientsClaim":true,"skipWaiting":true,"workboxConfig":{"cacheId":"workbox","globPatterns":["**/*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
